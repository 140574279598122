import { domainInfo } from '../utils/domain';

export const hubspotScript = `
  hbspt.forms.create({
    portalId: '145596771',
    formId: '78a8ea5e-0418-4aef-ab66-a563cb319203',
  });
`;

export const gtmScript = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-MMC84VP');
`;

export const matomoScript = `
  var profile = JSON.parse(localStorage.getItem('profile') || 'null');
  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" /
  _paq.push(["setDomains", [".darlean.com",".app.darlean.com",".app.darlean.eu",".app.darlean.kz",".darlean.eu","*.darlean.kz"]]);
  _paq.push(["enableCrossDomainLinking"]);
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  _paq.push(['setUserId', profile?.id]);
  (function() {
    var u="https://5qit.matomo.cloud/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '1']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/5qit.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
  })();
`;

export const matomoTagManagerScript = `
  var _mtm = window._mtm = window._mtm || [];
  _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
  (function() {
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/5qit.matomo.cloud/container_eJ0Zm0IU.js'; s.parentNode.insertBefore(g,s);
  })();
`;

const euBitrixLink = 'https://cdn.bitrix24.eu/b25053179/crm/site_button/loader_4_vq8w6m.js';
const idBitrixLink = 'https://cdn-ru.bitrix24.ru/b21828152/crm/site_button/loader_28_kz7gk8.js';
const kzBitrixLink = 'https://cdn-ru.bitrix24.ru/b21828152/crm/site_button/loader_8_mg8zve.js';

const getBitrixLink = () => {
	if (domainInfo.isEU()) return euBitrixLink;
	if (domainInfo.isKZ()) return kzBitrixLink;
	if (domainInfo.isDarIo()) return kzBitrixLink;
	if (domainInfo.isID()) return idBitrixLink;

	return idBitrixLink;
};

export const getBitrixScript = () => `
  (function(w,d,u){
      // For some reason we need amd.js to load React and ReactDOM in correct order (https://github.com/single-spa/single-spa/issues/769)
      // But bitrix24 loader uses amd's define function incorrectly
      // so here we temporary remove 'define' form Window and then restore it
        if (window.BX && window.BX.SiteButton) {
          return;
        } 
        const globalDefine = window.define;

        var s = d.createElement('script');
        s.async=true;
        s.onerror = () => window.define = globalDefine;
        s.src=u+'?'+(Date.now()/60000|0);
        var h=d.getElementsByTagName('script')[0];
        h.parentNode.insertBefore(s,h);

        delete window.define;
        // Track BX global variable, when it's defined restore global 'define'
        Object.defineProperty(window, 'BX', {
            configurable: true,
            enumerable: true,
            get: function() {
              return this._BX;
            },
            set: function(val) {
              this._BX = val;
              if (val.SiteButton) {
                window.define = globalDefine;
              }
            }
        });
  })(window,document,'${getBitrixLink()}');
`;
