import { useCallback, useEffect, useRef } from 'react';

import { Analytics, analytics } from '@dar/api-interfaces';
import { getBitrixScript, gtmScript, hubspotScript, matomoScript, matomoTagManagerScript } from '@topbar/src/shared/analytics';
import { COOKIE_SETTINGS_KEY } from '@topbar/src/shared/constants';

import { CookieSettingsOption, getSettings } from '../cookie-drawer/utils';

// TODO: uncomment when chat-bot is needed (maybe never)
// import { useTranslation } from 'react-i18next';
// import { environment } from '@topbar/src/environments/environment';
// import { useAuth } from '@topbar/src/topbar/providers/auth-provider';
// import { domainInfo } from '@topbar/src/utils/domain';
// import { ScriptLoaderService, ScriptPos } from '@topbar/src/utils/script-loader';
// import dfBotIcon from '../../assets/botface.png';

// const loader = new ScriptLoaderService();

// const isProd = domainInfo.isCOM() || domainInfo.isID() || domainInfo.isKZ() || domainInfo.isDarIo();

type CookieScriptsProps = {
	onOpen?: () => void;
	open?: boolean;
};
export const useCookieScripts = (props: CookieScriptsProps = {}) => {
	// TODO: uncomment when chat-bot is needed (maybe never)
	// const { i18n } = useTranslation();
	// const { authenticated } = useAuth();
	// const auth = JSON.parse(localStorage.getItem('dms-auth'));
	// const profile = JSON.parse(localStorage.getItem('profile'));

	const cookieScriptLoaded = useRef(false);

	const addHubspot = useCallback(() => {
		try {
			const script1 = document.createElement('script');
			script1.type = 'text/javascript';
			script1.src = 'https://js-eu1.hsforms.net/forms/embed/v2.js';

			script1.onload = () => {
				const script2 = document.createElement('script');
				script2.type = 'text/javascript';
				script2.async = true;
				script2.innerHTML = hubspotScript;
				document.body.appendChild(script2);

				script2.onload = () => {
					// const form = document.getElementById('hbspt-form-9e8e8d67-dd2b-48bd-8ed8-547346bc81f9');
					const form = document.getElementById('hs-form-iframe-0').parentElement;
					form.style.position = 'fixed';
				};
			};

			document.body.appendChild(script1);
		} catch (e) {
			//
		}
	}, []);

	useEffect(() => {
		const cookieSettings = JSON.parse(localStorage.getItem(COOKIE_SETTINGS_KEY));
		if (cookieSettings === null) {
			props && props.onOpen && props.onOpen();
		}
	}, []);

	const loadScripts = () => {
		const cookieSettings: CookieSettingsOption[] = JSON.parse(localStorage.getItem(COOKIE_SETTINGS_KEY));

		if (!!cookieSettings && cookieScriptLoaded.current === false) {
			const analyticsScript: Record<Analytics, string> = {
				'bitrix-eu': getBitrixScript(),
				'bitrix-kz': getBitrixScript(),
				matomo: matomoScript,
				'matomo-tag-manager': matomoTagManagerScript,
				'ga-4': gtmScript,
				hubspot: null,
			};
			cookieSettings.forEach((setting) => {
				if (analytics.includes(setting?.name)) {
					if (setting.name === 'hubspot') {
						addHubspot();
					} else {
						try {
							const script = document.createElement('script');
							script.type = 'text/javascript';
							script.async = true;
							script.innerHTML = analyticsScript[setting.name];
							document.body.appendChild(script);
						} catch (e) {
							//
						}
					}
				}
			});
			cookieScriptLoaded.current = true;
		}
	};
	useEffect(() => {
		loadScripts();
	}, []);

	// TODO: uncomment when chat-bot is needed (maybe never)
	// useEffect(() => {
	// 	let dfMessenger;
	// 	const lang = i18n.language === 'kz' ? 'kk' : i18n.language;
	// 	if ((!isProd || domainInfo.isDarIo()) && authenticated) {
	// 		loader
	// 			.loadScriptFile('https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1', ScriptPos.BODY_START)
	// 			.then(() => {
	// 				dfMessenger = document.createElement('df-messenger');
	// 				dfMessenger.setAttribute('chat-title', 'DARik Bot');
	// 				dfMessenger.setAttribute('agent-id', environment().dfAgentID);
	// 				dfMessenger.setAttribute('language-code', lang);
	// 				dfMessenger.setAttribute('chat-icon', dfBotIcon);
	// 				dfMessenger.setAttribute('user-id', profile?.id);
	// 				dfMessenger.setAttribute('session-id', auth?.id_token);
	// 				dfMessenger.setAttribute('intent', 'welcome');
	// 				dfMessenger.setAttribute('wait-open', '1');
	// 				document.body.appendChild(dfMessenger);

	// 				dfMessenger.addEventListener('df-messenger-loaded', () => {
	// 					const dfMessengerWrapper = dfMessenger?.shadowRoot?.querySelector('.df-messenger-wrapper');
	// 					if (dfMessengerWrapper) {
	// 						dfMessengerWrapper.style.top = '20px';
	// 						dfMessengerWrapper.style.bottom = 'auto';
	// 					}

	// 					const dfChatArea = dfMessengerWrapper?.shadowRoot?.querySelector('.chat-wrapper');
	// 					if (dfChatArea) {
	// 						dfChatArea.style.top = '20px';
	// 						dfChatArea.style.bottom = 'auto';
	// 					}

	// 					const dfSVGIcon = dfMessenger?.shadowRoot?.querySelector('.df-chat-icon > svg');
	// 					if (dfSVGIcon) {
	// 						dfSVGIcon.style.width = '25px';
	// 						dfSVGIcon.style.height = '25px';
	// 					}

	// 					const dfIcon = dfMessenger?.shadowRoot?.querySelector('#widgetIcon');
	// 					if (dfIcon) {
	// 						dfIcon.style.bottom = '-21px';
	// 						dfIcon.style.right = '290px';
	// 						dfIcon.style.height = '35px';
	// 						dfIcon.style.width = '35px';
	// 					}

	// 					const dfCloseSvg = dfMessenger?.shadowRoot?.querySelector('#closeSvg');
	// 					if (dfCloseSvg) {
	// 						dfCloseSvg.style.top = '6px';
	// 						dfCloseSvg.style.left = '6px';
	// 						dfCloseSvg.style.width = '23px';
	// 						dfCloseSvg.style.height = '23px';
	// 					}

	// 					const dfChatIcon = dfMessenger?.shadowRoot?.querySelector('.df-chat-icon');
	// 					if (dfChatIcon) {
	// 						dfChatIcon.style.top = '6px';
	// 						dfChatIcon.style.left = '0';
	// 					}
	// 					setTimeout(function () {
	// 						const dfBotIcon = dfMessenger?.shadowRoot?.querySelector('#widgetIcon > img');
	// 						if (dfSVGIcon) {
	// 							dfBotIcon.style.width = '25px';
	// 							dfBotIcon.style.height = '25px';
	// 							dfBotIcon.style.top = '5px';
	// 							dfBotIcon.style.left = '5px';
	// 						}
	// 					}, 0);
	// 				});
	// 			});
	// 	}
	// 	return () => {
	// 		if (dfMessenger) document.body.removeChild(dfMessenger);
	// 	};
	// }, [i18n.language, authenticated]);

	return {
		loadScripts: () => {
			//Force loading cookies
			localStorage.setItem(COOKIE_SETTINGS_KEY, JSON.stringify(getSettings()));
			loadScripts();
		},
	};
};
