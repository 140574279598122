import { useCallback } from 'react';

import { IBotControlContext } from '../shared/constants';

export type UseBot = {
	onBotClick: () => void;
	botControl: IBotControlContext;
};

export function useBot(useBotControl: () => IBotControlContext): UseBot {
	const botControl = useBotControl();

	const onBotClick = useCallback(() => {
		botControl.toggleBotWidget();
	}, [botControl]);

	return {
		onBotClick,
		botControl,
	};
}
