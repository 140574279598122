import React, { ReactElement, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import chatBotLogo from '@topbar/src/assets/darvis-logo.png';
import { BadgeContext } from '@topbar/src/contexts/badge-context/badge-context';

import { TypographyVariant } from '../sidebar-theme';
import { StyledChatBotSection } from './chat-bot-section-styles';
import { ChatBotSectionProps } from './chat-bot-section-types';
import { useChatBotSectionAnimations } from './hooks/use-chat-bot-section-animations';
import { BotContext } from '@topbar/src/contexts/bot-context';

const CHAT_BOT_SECTION_CONTAINER_ID = 'chat-bot-section-container-id';
const CHAT_BOT_TEXT_SECTION_ID = 'chat-bot-text-section-id';

export function ChatBotSection({ collapsed }: ChatBotSectionProps): ReactElement {
	const { t } = useTranslation();
  const botContext = useContext(BotContext);

	const { badges } = useContext(BadgeContext);
	const unreadMessagesCounter = badges?.BOT || '';
  const shouldShowBot = typeof botContext?.onBotClick === 'function';

	useChatBotSectionAnimations({
		chatBotSectionContainerId: CHAT_BOT_SECTION_CONTAINER_ID,
		chatBotSectionTextContainerId: CHAT_BOT_TEXT_SECTION_ID,
		collapsed,
	});

  const onBotClick = useCallback(() => {
    if (shouldShowBot) {
      botContext?.onBotClick();
    }
  }, [botContext, shouldShowBot]);

  if (!shouldShowBot) {
    return null;
  }

	return (
		<StyledChatBotSection.Container id={CHAT_BOT_SECTION_CONTAINER_ID} onClick={onBotClick} className='with-border'>
			<img src={chatBotLogo} alt='' width={32} style={{ minWidth: '32px' }} />
			<StyledChatBotSection.AnimatedBox id={CHAT_BOT_TEXT_SECTION_ID}>
				<StyledChatBotSection.TextContainer>
					<StyledChatBotSection.Title>{t('chat_bot.title')}</StyledChatBotSection.Title>
					<StyledChatBotSection.SubTitle customVariant={TypographyVariant.SMALL}>
						{t('chat_bot.subtitle')}
					</StyledChatBotSection.SubTitle>
				</StyledChatBotSection.TextContainer>
				<StyledChatBotSection.Counter>{unreadMessagesCounter}</StyledChatBotSection.Counter>
			</StyledChatBotSection.AnimatedBox>
		</StyledChatBotSection.Container>
	);
}
