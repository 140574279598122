import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { Workspace } from '@dar/api-interfaces';
import { Profile } from '@dartech/griffon-auth';
import AuthLayout from '@topbar/src/pages/auth/auth-layout/auth-layout';
import { getSurvey, getSurveyStructure } from '@topbar/src/services/workspace';

import routes from './app-lazy';

interface AppRoutingProps {
	authenticated: boolean;
	workspace?: Workspace;
	profile?: Profile;
	isWorkspaceArea: boolean;
}

const AppRouting = ({ authenticated, workspace, profile, isWorkspaceArea }: AppRoutingProps) => {
	const history = useHistory();

	useEffect(() => {
		// Redirect to survey if the workspace survey is not finished and the user is authenticated
		if (workspace && workspace.hasOwnProperty('isSurveyFinished') && !workspace.isSurveyFinished && authenticated) {
			redirectToSurvey();
		}
	}, [workspace, authenticated]);

	// Function to redirect to the survey page
	const redirectToSurvey = async () => {
		const survey = await getSurvey(workspace.id, profile.id);
		if (survey.currentStep) {
			history.replace(`/workspace/survey?step=${survey.currentStep}`);
		} else {
			const surveyStructure = await getSurveyStructure();
			history.replace(`/workspace/survey?step=${surveyStructure[0].key}`);
		}
	};

	return (
		<Suspense fallback>
			<Switch>
				<Route path='/auth/social-mobile' component={routes.LoginSocialMobile} />
				<Route path='/auth/from-landing' component={routes.LoginFromLanding} />
				<Route path='/auth/from-landing-social' component={routes.LoginFromLandingSocial} />
				<Route path='/invitation/:invitationId' component={routes.InvitationOnboarding} />
				<Route path='/congratulations' component={routes.Congrats} />
				<Route path='/join-meeting/:meetingId' component={routes.JoinMeeting} />
				<Route path='/docflow-sign' component={routes.Docflow} />
				<Route path='/company/select' component={routes.Workspaces} />
				<Route path='/create-workspace' component={routes.CreateWorkspace} />
				<Route path='/' component={AuthLayout} />
				{/* Redirect to company selection if authenticated but no workspace and not in workspace area */}
				{authenticated && !workspace && !isWorkspaceArea && <Redirect from='*' to='/company/select' />}
			</Switch>
		</Suspense>
	);
};

export { AppRouting };
