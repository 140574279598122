import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export type UseChat = {
	onChatClick: () => void;
};

export function useChat(): UseChat {
	const history = useHistory();

	const onChatClick = useCallback(() => {
		window.location.hash = '#/chat';
	}, [history]);

	return {
		onChatClick,
	};
}
