import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getLanguages } from '@topbar/src/services/language';
import { domainInfo } from '@topbar/src/utils/domain';

import { useAnchorEl } from '../sidebar/hooks/use-anchor-el';

export const useAuthHeader = () => {
	const history = useHistory();
	const {
		i18n: { language, changeLanguage },
		t,
	} = useTranslation();
	const { isOpen, anchorEl, onModalOpen, onModalClose } = useAnchorEl();

	const [languages, setLanguages] = useState<{ id: string; name: string }[]>([]);

	const currentLang = useMemo(() => {
		return (
			languages.find((lang) => lang.id === language || (language === 'kk' && lang.id === 'kz')) || { name: 'English', id: 'en' }
		);
	}, [language, languages]);

	const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		const { path } = e.currentTarget.dataset;
		history.push(path);
	};

	useEffect(() => {
		if (!domainInfo.isCOM()) {
			loadLanguages();
		}
	}, []);

	async function loadLanguages() {
		const langs = await getLanguages();
		setLanguages(domainInfo.isEU() ? langs.filter((lang) => lang.id !== 'ru') : langs);
	}

	const onChange = (e: React.MouseEvent<HTMLLIElement>) => {
		const { lang } = e.currentTarget.dataset;
		changeLanguage(lang);

		// FIXME: i don't know why changeLanguage doesn't change i18nextLng in localStorage
		// set i18nextLng in localStorage
		localStorage.setItem('i18nextLng', lang);

		window.location.reload();
		onModalClose();
	};

	return { currentLang, onClick, onChange, t, isOpen, anchorEl, onModalOpen, onModalClose, languages };
};
