import { init } from 'i18next';
import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import {
	Toaster, // theme
} from '@dartech/dms-ui';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { ChatBotProviderWrapper } from './components/chat-bot-provider/chat-bot-provider';
import { BadgeProvider } from './contexts/badge-context/badge-provider';
import './i18';
import './styles.scss';
import theme from './theme';
import { App } from './topbar/app';
import { AuthProvider } from './topbar/providers/auth-provider';
import { BrandProvider } from './topbar/providers/brand-provider';

type langType = 'en' | 'kz' | 'ru';

// ClassNameGenerator.configure((componentName) => `${STYLES_PREFIX}-${componentName}`); This is really unstable

const queryClient = new QueryClient({
	defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

init();

const Root = () => {
	const lang = localStorage.getItem('i18nextLng');

	return (
		<ThemeProvider theme={theme}>
			<StyledThemeProvider theme={theme}>
				<Suspense fallback={null}>
					<QueryClientProvider client={queryClient}>
						<BrowserRouter>
							<AuthProvider>
								<BadgeProvider>
									<BrandProvider>
										<ChatBotProviderWrapper>
											<CssBaseline />
											<Toaster locale={lang as langType} />
											<App />
										</ChatBotProviderWrapper>
									</BrandProvider>
								</BadgeProvider>
							</AuthProvider>
						</BrowserRouter>
					</QueryClientProvider>
				</Suspense>
			</StyledThemeProvider>
		</ThemeProvider>
	);
};

export { Root };
