import axios from 'axios';

import {
	CreateWorkspaceInfo,
	Invitation,
	InvitationCreateRequest,
	Survey,
	SurveyQuestion,
	Workspace,
	WorkspaceGeneralResponse,
	WorkspaceToken,
} from '@dar/api-interfaces';

import { authService } from '../dar-dms-topbar';
import { environment } from '../environments/environment';
import { httpClient } from './clients';

export const getWorkspace = (id: string) => {
	//need workspaceauth
	return httpClient.get<Workspace>(`${environment().hcmsApiRoot}/main-api/workspace/v2/${id}`).then((res) => res.data);
};

export const getWorkspaceList = (id: string) => {
	return httpClient.get<Workspace[]>(`${environment().hcmsApiRoot}/main-api/workspace?employeeId=${id}`).then((res) => res.data);
};

export const createWorkspace = (data: CreateWorkspaceInfo) => {
	return httpClient
		.post<WorkspaceGeneralResponse>(`${environment().hcmsApiRoot}/main-api/workspace/v2`, data, {
			transformRequest: (data, headers) => {
				delete headers['Workspace-Authorization'];
				headers['Content-Type'] = 'application/json';
				headers.post['Content-Type'] = 'application/json';
				return JSON.stringify(data);
			},
		})
		.then((res) => res.data);
};

export const updateWorkspace = (id: string, data: Partial<Workspace>) => {
	return httpClient
		.put<WorkspaceGeneralResponse>(`${environment().hcmsApiRoot}/main-api/workspace/v2/${id}`, data)
		.then((res) => res.data);
};

export const uploadLogo = (data: FormData) => {
	return httpClient.post<string>(`${environment().hcmsApiRoot}/main-api/aws`, data).then((res) => res.data);
};

export const updateIndustryAndSpecialization = (industryId: string, specializationId: string, id: string) =>
	httpClient
		.put<any>(
			`${
				environment().hcmsApiRoot
			}/main-api/employee/update?employeeId=${id}&specializationId=${specializationId}&industryId=${industryId}`
		)
		.then((res) => res.data);

export const getInvitationItem = (id: string) => {
	return axios.get<Invitation[]>(`${environment().hcmsApiRoot}/main-api/invitation/${id}`).then((res) => res.data);
};

export const createInvitationRequest = (data: InvitationCreateRequest) => {
	const workspaceToken = authService.getWorkspaceToken();
	return httpClient
		.post<Invitation[]>(`${environment().hcmsApiRoot}/main-api/invitation`, data, {
			headers: {
				'dar-dms-user-id': JSON.parse(localStorage.getItem('profile'))?.id,
				'dar-dms-org-id': JSON.parse(localStorage.getItem('workspace'))?.id,
				'Workspace-Authorization': workspaceToken,
			},
		})
		.then((res) => res.data);
};

export const updateInvitationReceived = (id: string) => {
	return httpClient.put<Invitation[]>(`${environment().hcmsApiRoot}/main-api/invitation/${id}/received`).then((res) => res.data);
};

export const getWorkspaceToken = (workspaceId: string, employeeId: string) => {
	return httpClient
		.get<WorkspaceToken>(`${environment().hcmsApiRoot}/main-api/workspace/v2/${workspaceId}/token`, {
			headers: {
				'Workspace-Authorization': '',
			},
		})
		.then((res) => res.data);
};

export const getSurvey = (workspaceId: string, employeeId: string) => {
	return httpClient
		.get<Survey>(`${environment().hcmsApiRoot}/main-api/survey`, {
			params: {
				workspaceId,
				employeeId,
			},
		})
		.then((res) => res.data);
};

export const updateSurvey = (survey: Partial<Survey>) => {
	return httpClient.put<Survey>(`${environment().hcmsApiRoot}/main-api/survey`, survey).then((res) => res.data);
};

export const getSurveyStructure = () => {
	return httpClient
		.get<SurveyQuestion[]>(`${environment().hcmsApiRoot}/main-api/survey/survey-structure`)
		.then((res) => res.data);
};
