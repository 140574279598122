import React, { FC, ReactElement, useEffect, useState } from 'react';

import { BotContext } from '../../contexts/bot-context';
import { ChatContext } from '../../contexts/chat-context';
import { useBot } from '../../hooks/useBot';
import { useChat } from '../../hooks/useChat';
import { IBotControlContext, IProviderProps } from '../../shared/constants';

type ChatData = {
	AIBotChat: FC;
};

type BotData = {
	AIBotWidget: FC;
	BotControlContextProvider: FC<IProviderProps>;
	useBotControl: () => IBotControlContext;
};

function isChatData(chatData: any): chatData is ChatData {
	return chatData && 'AIBotChat' in chatData;
}

function isBotData(botData: any): botData is BotData {
	return botData && 'AIBotWidget' in botData && 'BotControlContextProvider' in botData && 'useBotControl' in botData;
}

export const ChatBotProviderWrapper: FC = ({ children }) => {
	const [chatData, setChatData] = useState<ChatData | Record<string, never>>({});
	const [botData, setBotData] = useState<BotData | Record<string, never>>({});

	useEffect(() => {
		async function getChatAndBotModules() {
			try {
				const chatData = await (window as any).System.import('@dar-dms/chat');
				setChatData(chatData);
				const botData = await (window as any).System.import('@dar-dms/bot');
				setBotData(botData);
			} catch {
				console.error('can not import chat or bot module');
				setChatData({});
				setBotData({});
				return;
			}
		}

		getChatAndBotModules();
	}, []);

	if (isChatData(chatData) && isBotData(botData)) {
		return (
			<ChatBotProvider chatData={chatData} botData={botData}>
				{children}
			</ChatBotProvider>
		);
	}

	return children as ReactElement;
};

interface ChatBotProviderProps {
	chatData: ChatData;
	botData: BotData;
}

const ChatBotProvider: FC<ChatBotProviderProps> = ({ chatData, botData, children }) => {
	const { AIBotChat } = chatData;
	const { AIBotWidget, useBotControl, BotControlContextProvider } = botData;
	const { onChatClick } = useChat();
	const { onBotClick, botControl } = useBot(useBotControl);

	return (
		<ChatContext.Provider value={{ onChatClick }}>
			<BotControlContextProvider value={botControl}>
				<BotContext.Provider value={{ onBotClick, botControl }}>
					{children}
					<AIBotWidget />
					<AIBotChat />
				</BotContext.Provider>
			</BotControlContextProvider>
		</ChatContext.Provider>
	);
};
