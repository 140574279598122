import { lazy } from 'react';

import { ProjectSettingsNav } from '@topbar/src/components/project-nav/project-settings-nav';

import { BudgetSettingsNav } from '../../components/budget-nav/budget-settings-nav';

const Topbar = lazy(() => import('../topbar').then((v) => ({ default: v.Topbar })));
const JoinMeeting = lazy(() => import('../../pages/join-meeting/join-meeting').then((v) => ({ default: v.JoinMeeting })));
const Congrats = lazy(() => import('../../pages/congrats').then((v) => ({ default: v.Congrats })));
const SignupCode = lazy(() => import('../../pages/signup-code/signup-code-page').then((v) => ({ default: v.SignupCodePage })));
const SignUpSocialCode = lazy(() =>
	import('../../pages/signup-code/signup-social-code-page').then((v) => ({ default: v.SignupSocialCodePage }))
);
const CreatePassword = lazy(() =>
	import('../../pages/create-password/create-password').then((v) => ({ default: v.CreatePasswordPage }))
);

const InvitationOnboarding = lazy(() =>
	import('../../pages/invitation-onboarding/invitation-onboarding').then((v) => ({ default: v.InvitationOnboarding }))
);
const UpdatePassword = lazy(() =>
	import('../../pages/update-password/update-password').then((v) => ({ default: v.UpdatePassword }))
);
const LoginFromLanding = lazy(() =>
	import('../../pages/login-from-landing/login-from-landing').then((v) => ({ default: v.LoginFromLandingPage }))
);
const LoginFromLandingSocial = lazy(() =>
	import('../../pages/login-from-landing/login-from-landing-social').then((v) => ({ default: v.LoginFromLandingSocial }))
);
const LoginSocial = lazy(() => import('../../pages/login-social/login-social').then((v) => ({ default: v.LoginSocial })));
const LoginSocialMobile = lazy(() =>
	import('../../pages/login-social/login-social-mobile').then((v) => ({ default: v.LoginSocialMobile }))
);

const Nav = lazy(() => import('../../components/nav/menu-nav').then((v) => ({ default: v.MenuNav })));
const SettingsNav = lazy(() => import('../../components/nav/settings-nav').then((v) => ({ default: v.SettingsNav })));
const CnbSettingsNav = lazy(() => import('../../components/nav/cnb-settings-nav').then((v) => ({ default: v.CnbSettingsNav })));
const ProjectTabNav = lazy(() =>
	import('../../components/project-nav/project-tab-nav').then((v) => ({ default: v.ProjectTabNav }))
);
const ProcessesSettingsNav = lazy(() =>
	import('../../components/nav/processes-settings-nav').then((v) => ({
		default: v.ProcessesSettingsNav,
	}))
);
const Docflow = lazy(() => import('../../pages/docflow/docflow').then((v) => ({ default: v.Docflow })));
const CreateWorkspace = lazy(() => import('../../pages/create-workspace/create-workspace'));
const BudgetTabNav = lazy(() => import('../../components/budgets-nav/budget-tab-nav').then((v) => ({ default: v.BudgetTabNav })));

// NEW AUTH

const SignUpPage = lazy(() => import('../../pages/auth/signup/signup.page'));
const SignInPage = lazy(() => import('../../pages/auth/signin/signin.page'));
const CheckEmail = lazy(() => import('../../pages/auth/check-email/check-email.page'));
const CreateAccount = lazy(() => import('../../pages/auth/create-account/create-account.page'));
const ResetPasswordOtp = lazy(() => import('../../pages/auth/reset-password-otp/reset-password-otp.page'));
const ResetPassword = lazy(() => import('../../pages/auth/reset-password/reset-password.page'));
const HowUse = lazy(() => import('../../pages/auth/how-use/how-use.page'));
const ManageTools = lazy(() => import('../../pages/auth/manage-tools/manage-tools.page'));
const BusinessName = lazy(() => import('../../pages/auth/business-name/business-name.page'));
const Survey = lazy(() => import('../../pages/auth/survey/survey.page'));
const Workspaces = lazy(() => import('../../pages/workspaces'));

export default {
	Topbar,
	Nav,
	JoinMeeting,
	Congrats,
	SignupCode,
	SignUpSocialCode,
	CreatePassword,
	InvitationOnboarding,
	UpdatePassword,
	SettingsNav,
	ProjectSettingsNav,
	ProjectTabNav,
	ProcessesSettingsNav,
	LoginFromLanding,
	LoginFromLandingSocial,
	LoginSocial,
	LoginSocialMobile,
	BudgetSettingsNav,
	CnbSettingsNav,
	Docflow,
	BudgetTabNav,
	SignUpPage,
	SignInPage,
	CheckEmail,
	CreateAccount,
	ResetPasswordOtp,
	ResetPassword,
	HowUse,
	ManageTools,
	BusinessName,
	Survey,
	Workspaces,
	CreateWorkspace,
};
